<template>
  <div class="bodycontain">
    <template v-if="infodata">
      <div class="outcontain">
        <div class="titletext">{{ iteminfo.title }}</div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">{{ iteminfo.user_name }}</div>
            <div class="text text2">{{ iteminfo.student_sn }}</div>
          </div>
          <div class="itemright">
            <div class="text">实习形式:</div>
            <div class="text text2">{{ iteminfo.type | typeformat }}</div>
          </div>
        </div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">{{ iteminfo.college_name }}/</div>
            <div class="text">{{ iteminfo.major_name }}/</div>
            <div class="text">{{ iteminfo.class_name }}</div>
          </div>
          <div class="itemright">
            <div class="text">实习时间:</div>
            <div class="text text2">
              {{ iteminfo.start_date | dataformat }} ~ {{
                iteminfo.end_date | dataformat
              }}
            </div>
          </div>
        </div>
        <div class="itemcontain">
          <div class="itemleft">
            <div class="text">实习计划:</div>
            <div class="text text2">{{ iteminfo.plan_name }}</div>
          </div>
          <div class="itemright">
            <div class="text">总结时间:</div>
            <div class="text text2">{{ infodata.created_at | dataformat }}</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="contenttext">
          <div v-html="infodata.content"></div>
        </div>
        <!-- 目前后端储存数据地址在调整,设计附件上传以及下载的部分都进行隐藏 v-if="false" -->
        <template v-if="Array.isArray(infodata.file)">
          <div class="downbt" v-for="(item,index) in infodata.file" :key="index">
            <div>附件{{index}}：{{item.name}}</div>
            <!-- <el-image class="elimage" :src="icon1" /> -->
            <el-image class="elimage" :src="icon2" @click="download(item)" />
          </div>
        </template>
        <template v-else>
          <div class="downbt">附件：{{infodata.file}}</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
const typemap = ["自主报名,集中安排", "集中安排", "自主报名"];
import dayjs from "dayjs";
import Mainpage from "@/views/news/components/mainpage.vue";

import { get_report_deatil, getinfopracticestudentreport } from '@/api/practicestudentreport'
export default {
  name: "News",
  components: {
    Mainpage,
  },
  data() {
    return {
      icon1: require("@/assets/查看.png"),
      icon2: require("@/assets/下载.png"),
      infodata: {},
      iteminfo: {},
      id: '',
      practice_plan_id: '',
      student_id: ''
    };
  },
  filters: {
    typeformat(val) {
      return typemap[val];
    },
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() { },
  methods: {
    initdata(item) {
      this.id = item.id
      this.practice_plan_id = item.practice_plan_id
      this.student_id = item.student_id ? item.student_id : ''
      this.initLogInfo()
      this.init_repoet_info()
    },
    initLogInfo() {
      const params = {
        id: this.id,
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id
      }
      getinfopracticestudentreport(params).then(res => {
        if (res.code == 0) {
          this.infodata = res.data
        }
      })
    },
    init_repoet_info() {
      const params = {
        practice_plan_id: this.practice_plan_id,
        student_id: this.student_id
      }
      get_report_deatil(params).then(res => {
        if (res.code == 0) {
          this.iteminfo = res.data
        }
      })
    },
    download(item) {
      var ele = document.createElement("a"); // 创建下载链接
      ele.download = item.name; //设置下载的名称
      ele.style.display = "none"; // 隐藏的可下载链接
      ele.href = item.url;
      // 绑定点击时间
      document.body.appendChild(ele);
      ele.click();
      // 然后移除
      document.body.removeChild(ele);
    },
  },
};
</script>
<style scoped lang="scss">
.bodycontain {
  .outcontain {
    padding: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
    .titletext {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 700;
      color: #3a3a3a;
    }
    .itemcontain {
      margin-bottom: 10px;
      padding-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .itemleft {
        display: flex;
        flex-basis: 400px;
        justify-content: flex-start;
        align-items: center;
      }
      .itemright {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        color: #3a3a3a;
      }
      .text2 {
        margin-left: 10px;
      }
    }
  }
  .content {
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    .contenttext {
      padding: 20px;
    }
    .downbt {
      margin-top: 30px;
      margin-left: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .elimage {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
