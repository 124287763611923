<template>
  <div class="contain-box">
    <div class="contain">
      <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
        :header-cell-style="{
          'font-size': '15px',
          color: '#666666',
          'font-weight': 'bold',
          background: '#F7F7F7',
        }" :row-style="{
          'font-size': '15px',
          color: '#222222',
          'font-weight': '400',
        }">
        <el-table-column prop="title" label="标题">

        </el-table-column>
        <el-table-column prop="created_at" label="提交时间">
          <template slot-scope="{row}">
            {{row.created_at | dataformat}}
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" show-overflow-tooltip>
          <template slot-scope="{row}">
            <div v-html="row.content" style="overflow:hidden;max-height: 50px"></div>
          </template>
        </el-table-column>
        <el-table-column prop="reply_content" label="评语"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="item-right">
              <div class="bt" @click="checkbt(scope.row)">查看</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <Customdialog ref="customdialog" type="max" width="40%" title="日志查看" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <Loglook ref="loglook"></Loglook>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { getpracticeloglist } from "@/api/practiceresult";
import dayjs from "dayjs";
import Customdialog from "@/components/customdialog.vue";
import Loglook from "./loglook";
export default {
  name: "journals",
  props: {
    log_type: {
      type: String | Number,
      default: 0,
    },
    practice_plan_id: {
      type: String | Number,
    },
  },
  components: {
    Customdialog,
    Loglook,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        practice_plan_id: this.practice_plan_id,
        type: this.log_type,
        page: 1,
        per_page: 10,
      },
    };
  },
  watch: {
    log_type: {
      handler(val) {
        this.queryParams.type = val
        this.init();
      },
      immediate: true
    }
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    init() {
      getpracticeloglist(this.queryParams).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    checkbt(row) {
      this.$refs.customdialog.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.loglook.initdata(row);
      });
    },
    changePage(data) {
      this.queryParams.page = data;
      this.init();
    },
  },
};
</script>

<style scoped lang="scss">
.contain-box {
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  width: 100%;
  .elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
  .bt {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0773fc;
    user-select: none;
    cursor: pointer;
  }
}
</style>
